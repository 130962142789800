import React from "react"
import styled from "styled-components"

const AboutMe = () => {
  return (
    <Article>
      <TextBar>
        <h1>
          <Bold>
            "My name is <Highlight>John</Highlight>, and I decided to learn programming
            because I wanted to do something that had a <Mark>positive</Mark>{" "}
            impact. I want to build things and make the world a better place."
          </Bold>
        </h1>
      </TextBar>

      <Block>
        <br />
        <p>
          I wanted to feel good after making something <Mark>different</Mark>.
        </p>
        <br />
        <p>
          I am a <Highlight>Front End Developer</Highlight> specialized in people. My
          goal is to bring their <Mark>thoughts</Mark> and <Mark>ideas</Mark> to reality and
          ensure that the process is the most <Highlight>honest</Highlight>, <Highlight>efficient</Highlight>, and <Highlight>professional</Highlight>.
        </p>
        <p>
          I follow a <Highlight>holistic</Highlight> process to bring any kind of project
          to fruition, searching for harmony and <Mark>balance</Mark> between
          the different parts that compose a project.
        </p>{" "}
        <br />
        <p>
          When I code, my goal is to produce <Mark>clean</Mark>, <Mark>easy-to-maintain</Mark>, <Mark>modular</Mark>,
          and <Mark>reusable</Mark> code. I tried the <Highlight>Jamstack</Highlight> once and got
          hooked. I fell in love with its <Mark>efficiency</Mark>, <Mark>low cost</Mark>,
          development <Mark>speed</Mark>, and I firmly believe that for small
          and mid-sized projects, it is the best method.
        </p>
        <br />
        <p>
          I studied <Highlight>graphic design</Highlight> almost a decade ago, and I use that knowledge
          to create projects where utility meets design and where{" "}
          <Highlight>design serves usability</Highlight>.
        </p>
        <br />
        <p className="bolded">
          <Highlight>Purpose</Highlight> is the base of everything I do.
        </p>
        <p>
          I work with a diverse range of tech, and among the tech stack I use
          are <Highlight>HTML</Highlight>, <Highlight>CSS</Highlight>, <Highlight>VanillaJS</Highlight>, <Highlight>React</Highlight>, <Highlight>Gatsby</Highlight>, <Highlight>Git</Highlight>, <Highlight>Python</Highlight>,
          <Highlight>styled-components</Highlight>, <Highlight>GraphQL</Highlight>, <Highlight>SVG</Highlight>, and CMSs like <Highlight>Contentful</Highlight> or
          <Highlight>WordPress</Highlight>. I also have knowledge of the <Highlight>Adobe suite</Highlight>.
        </p>
        <br />
        <p>
          I believe in <Highlight>continuous improvement</Highlight> and I'm always
          learning new tech and developing new skills that I can use in my
          projects.
        </p>
        <br />
        <p>
          Outside of work, I like practicing <Highlight>Yoga</Highlight>, <Highlight>boxing</Highlight>, <Highlight>reading books</Highlight>, <Highlight>urban exploring</Highlight>, watching <Highlight>TV shows</Highlight>, all things <Highlight>audio-related</Highlight>, and
          <Highlight>electronics</Highlight>. I am a hobby collector always trying different things.
        </p>
      </Block>
    </Article>
  )
}

export default AboutMe

const TextBar = styled.section`
  font-size: 2.5rem;
  margin-bottom: 2rem;
`

const Article = styled.article`
  max-width: 1200px;
  margin: 2rem auto;
  font-size: 1.5rem;
`

const Block = styled.section`
  p {
    margin-top: 1rem;
    line-height: 1.6;
  }
  .bolded {
    font-weight: bold;
  }
`

const Highlight = styled.span`
  color: #00b1d2;
  font-weight: bold;
  background: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
  padding: 0.2rem 0.4rem;
  border-radius: 5px;
`

const Mark = styled.mark`
  color: white;
  background-color: purple;
  padding: 0.2rem 0.4rem;
  border-radius: 5px;
`

const Bold = styled.strong`
  font-weight: bold;
  display: inline-block;
  padding: 0.2rem 0.4rem;
  border-radius: 5px;
`
