import React from "react"
import Layout from "../components/layout"
import Hero from "../components/Hero/Hero"
import SEO from "../components/seo"
import AboutMe from "../components/About/AboutMe"
import { graphql } from "gatsby"
const about = ({ data }) => {
  return (
    <Layout>
      <SEO title="About Me" />
      <Hero img={data.indexBanner.childImageSharp.fluid} />
      <AboutMe />
      about page
    </Layout>
  )
}

export default about
export const query = graphql`
  query {
    indexBanner: file(relativePath: { eq: "banner4.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 2160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
